.tint {
	position: relative;
	float: left;
	cursor: pointer;
}

.tint:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 255, 255, 0.5);
	transition: background .3s linear;
}

.tint:hover:before {
	background: none;
}