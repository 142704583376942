/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;500&display=swap');

*:not(ion-icon) {
  font-family: 'Sora', sans-serif !important;
}

/** Ionic CSS Variables **/
:root {
 
  /** primary **/
  --ion-color-primary: #FD4F72;
  --ion-color-primary-rgb: 253, 79,  114;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,  0,  0;
  --ion-color-primary-shade: #df4664;
  --ion-color-primary-tint: #fd6180;

  /** secondary **/
  --ion-color-secondary: #4B34F8;
  --ion-color-secondary-rgb: 75,  52,  248;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,  255,  255;
  --ion-color-secondary-shade: #422eda;
  --ion-color-secondary-tint: #5d48f9;

  /** tertiary **/
  --ion-color-tertiary: #6fff52;
  --ion-color-tertiary-rgb: 111,  255,  82;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,  0,  0;
  --ion-color-tertiary-shade: #62e048;
  --ion-color-tertiary-tint: #7dff63;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {

    --ion-color-primary: #FD4F72;
    --ion-color-primary-rgb: 253,79,114;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #df4664;
    --ion-color-primary-tint: #fd6180;

    --ion-color-secondary: #4B34F8;
    --ion-color-secondary-rgb: 75,52,248;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #422eda;
    --ion-color-secondary-tint: #5d48f9;

    --ion-color-tertiary: #6fff52;
    --ion-color-tertiary-rgb: 111,255,82;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #62e048;
    --ion-color-tertiary-tint: #7dff63;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

:root {
  --ion-color-gold: #D1B000;
  --ion-color-gold-rgb: 209, 176, 0;
  --ion-color-gold-contrast: #000000;
  --ion-color-gold-contrast-rgb: 0, 0, 0;
  --ion-color-gold-shade: #b89b00;
  --ion-color-gold-tint: #d6b81a;
}

.ion-color-gold {
  --ion-color-base: var(--ion-color-gold);
  --ion-color-base-rgb: var(--ion-color-gold-rgb);
  --ion-color-contrast: var(--ion-color-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-shade);
  --ion-color-tint: var(--ion-color-gold-tint);
}
:root {
  --ion-color-dark-gold: #996515;
  --ion-color-dark-gold-rgb: 153, 101, 21;
  --ion-color-dark-gold-contrast: #ffffff;
  --ion-color-dark-gold-contrast-rgb: 255, 255, 255;
  --ion-color-dark-gold-shade: #875912;
  --ion-color-dark-gold-tint: #a3742c;
}

.ion-color-dark-gold {
  --ion-color-base: var(--ion-color-dark-gold);
  --ion-color-base-rgb: var(--ion-color-dark-gold-rgb);
  --ion-color-contrast: var(--ion-color-dark-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-gold-shade);
  --ion-color-tint: var(--ion-color-dark-gold-tint);
}

:root {
  --ion-color-top-bar: #000700;
  --ion-color-top-bar-rgb: 0, 7, 0;
  --ion-color-top-bar-contrast: #ffffff;
  --ion-color-top-bar-contrast-rgb: 255, 255, 255;
  --ion-color-top-bar-shade: #000600;
  --ion-color-top-bar-tint: #1a201a;
}

.ion-color-top-bar {
  --ion-color-base: var(--ion-color-top-bar);
  --ion-color-base-rgb: var(--ion-color-top-bar-rgb);
  --ion-color-contrast: var(--ion-color-top-bar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-top-bar-contrast-rgb);
  --ion-color-shade: var(--ion-color-top-bar-shade);
  --ion-color-tint: var(--ion-color-top-bar-tint);
}

/* DARK MODE SWICH*/

  body.dark {
  
    --ion-color-primary: #FD4F72;
    --ion-color-primary-rgb: 253, 79, 114;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #df4664;
    --ion-color-primary-tint: #fd6180;
  
    --ion-color-secondary: #4B34F8;
    --ion-color-secondary-rgb: 75, 52, 248;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #422eda;
    --ion-color-secondary-tint: #5d48f9;
  
    --ion-color-tertiary: #6fff52;
    --ion-color-tertiary-rgb: 111, 255, 82;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #62e048;
    --ion-color-tertiary-tint: #7dff63;
  
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
  
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
  
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
  
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
  
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
  
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }
  
  /*
     * iOS Dark Theme
     * -------------------------------------------
     */
  
  .ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;
  
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
  
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
  
    --ion-item-background: #000000;
  
    --ion-card-background: #1c1c1d;
  }
  
  .ios ion-modal.dark {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
  
  
  /*
     * Material Design Dark Theme
     * -------------------------------------------
     */
  
  .md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;
  
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
  
    --ion-border-color: #222222;
  
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
  
    --ion-item-background: #1e1e1e;
  
    --ion-toolbar-background: #1f1f1f;
  
    --ion-tab-bar-background: #1f1f1f;
  
    --ion-card-background: #1e1e1e;
  }


  @keyframes pulse {
      from {
        transform: scale(1.3);
        color: var(--ion-color-primary);
      }
  
      50% {
        transform: scale(0.95);
        color: var(--ion-color-secondary-shade);
      }
  
      to {
        transform: scale(1.3);
        color: var(--ion-color-primary);
      }
    }
  
    .fe-pulse {
      animation-name: pulse;
      animation-duration: 4s;
      animation-iteration-count: infinite;      
    }



        .tooltip {
          position: relative;
          display: inline-block;
          border-bottom: 1px dotted black;
        }
    
        .tooltip .tooltiptext {
          visibility: hidden;
          width: 120px;
          background-color: #555;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          position: absolute;
          z-index: 1;
          top: -5px;
          right: 105%;
          margin-left: -60px;
          opacity: 0;
          transition: opacity 0.3s;
        }
    
    
        .tooltip:hover .tooltiptext {
          visibility: visible;
          opacity: 1;
        }