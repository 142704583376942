.msg {
	padding: 7px;
	border-radius: 10px;
	margin-bottom: 4px;
	white-space: pre-wrap;
}

.other-msg {
	
	background-color: #4B34F8;
	color: white;
}

.my-msg {
	background-color: #ccc;
	color: black;
}

hr.line {
	border: 1px solid #313131;
	width: 175px;
}

.lobby {
	width: 400px;
	margin: auto;
}

.message-container {
	overflow: auto;
	border-radius: 5px;
	margin-bottom: 2px;
}

.user-message {
	text-align: right;
	padding-right: 5px;
	font-size: 18px;
}

.my-message {
	text-align: left;
	padding-left: 5px;
	font-size: 18px;
}

.message {
	display: inline-flex;
	margin: 0px auto 0px auto;
	padding: 5px;
	font-size: 18px;
	border-radius: 20px;
	margin-top: 3px;
}

.from-user {
	font-size: small;
	margin-bottom: 3px;
	margin-right: 5px;
}