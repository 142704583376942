
#auth-grid {
	width: 100%;
	height: 100%;
	background: #dfdfdf;
}

#auth-row {
	height: 100%;
}

#auth-button {
	font-weight: 300;
}

@media (min-width: 240px) and (max-width: 768px) {
	#auth-grid {
		background: white;
	}

	#auth-col {
		border: none;
	}
}
